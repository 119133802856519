<template>
  <v-container fluid>
    <v-row>
      <v-col cols="10">
        <v-text-field v-model="rule.name" label="Rule Title"></v-text-field>
      </v-col>
      <v-col cols="5">
        <v-select :items="images" v-model="rule.image" label="Required Images"></v-select>
      </v-col>
      <v-col cols="5">
        <v-select :items="questions" v-model="rule.questiontype" label="Question Type"></v-select>
      </v-col>
      <v-col cols="5">
        <v-text-field v-model="rule.Xaxis" label="X Axis"></v-text-field>
      </v-col>
      <v-col cols="5">
        <v-text-field v-model="rule.Yaxis" label="Y Axis"></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="multipleSelected">
      <v-col cols="10">
        <v-text-field v-model="rule.choices" label="Number of Choices"></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="rule.choices >= 1 && multipleSelected">
      <v-col v-for="index in parseInt(rule.choices)" :key="index" cols="5">
        <v-text-field v-model="rule.options.name" :label="'Choice ' + index"></v-text-field>
      </v-col>
    </v-row>
    <!-- <v-row v-if="rule.choices >= 1 && multipleSelected">
      <v-col v-for="(option, index) in rule.options" :key="index" cols="5">
        {{option}}
        <v-text-field :label="'Choice ' + [index+1]" v-model="rule.options"></v-text-field>
      </v-col>
    </v-row>-->
    <v-btn color="accent" @click="sendData">
      <v-icon left>mdi-content-save</v-icon>Save
    </v-btn>
  </v-container>
</template>
<script>
export default {
  props: {
    itemsData: {
      Type: Object
    }
  },
  data() {
    return {
      rule: {
        options: [
          {
            name: "dennis"
          }
        ]
      },
      images: ["none", "one", "many"],
      questions: [
        "yes/no",
        "numerical",
        "multiple choice (1 answer)",
        "open ended",
        "multiple choice (more than one choice)"
      ]
    };
  },
  watch: {
    itemsData: {
      handler: "setRules",
      immediate: true
    }
  },
  methods: {
    setRules(val) {
      this.rule = { ...val };
    },
    sendData() {
      // console.log(this.$route, "choices");
      this.rule.survey_id = this.itemsData.survey_id;
      console.log(this.rule, "data");
      // this.$emit("data", this.rule);
    }
  },
  computed: {
    multipleSelected: function() {
      if (
        this.rule.questiontype == "multiple choice (1 answer)" ||
        this.rule.questiontype == "multiple choice (more than one choice)"
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>