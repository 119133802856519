<template>
  <v-row>
    <v-col cols="4">
      <v-card>
        <v-card-title>Rule</v-card-title>
        <v-card-text>
          <Editor :editMode="editMode" :itemsData="itemsData" :initial="dataItem" @data="save" />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="8">
      <v-card>
        <v-card-title>Response</v-card-title>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import Editor from "./editor";
export default {
  components: {
    Editor
  },
  data() {
    return {
      dummy: "test",
      itemsData: {},
      dataItem: {},
      editMode: true,
      choices: []
    };
  },
  watch: {
    "$route.params.rule_id": {
      handler: "getChoices",
      immediate: true
    }
  },
  methods: {
    setId(id) {
      this.id = id;
    },
    getChoices(id) {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/getchoices/${id}`)
        .then(res => {
          console.log(res, "r");
          self.itemsData = res;
          self.choices = this.itemsData.options;
          console.log(self.choices, "choices");
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    save(data) {
      //logic comes here
      const url = "/rules/" + this.$route.params.id;
      const self = this;
      this.$store
        .dispatch("put", { url, data })
        .then(res => {
          console.log(res, "data");
          // self.$refs.snackbar.show("Channel created successfully", "green");
          self.$router.push("/rules");
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  },
  created() {}
};
</script>
<style scoped>
</style>